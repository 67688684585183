import React, { ReactNode } from 'react';

import { motion } from 'framer-motion';
export const MotionElement = ({
  children,
  delay,
  className,
  isNext,
  ...props
}: {
  children: ReactNode;
  className?: string;
  delay?: number;
  isNext?: boolean;
}) => {
  return (
    <motion.div
      className={`${className ?? ''} min-h100vh-padding`}
      {...props}
      initial={{ opacity: 0, x: isNext ? '100%' : '-100%' }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: isNext ? '-100%' : '100%' }}
      transition={{ type: 'spring', duration: 0.6, delay: delay ?? 0, bounce: 0.2 }}
    >
      {children}
    </motion.div>
  );
};
