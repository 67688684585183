import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Modal } from '@mui/material';

import PlaylistingModal from '@/components/promote-modals/PlaylistingModal';
import SessionProvider from '@/contexts/SessionContext';

const PlaylistingFlowButton = ({
  hideButton,
  isOpen,
  close,
}: {
  hideButton?: boolean;
  isOpen?: boolean;
  close?: () => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          close && close();
        }}
      >
        <>
          <SessionProvider>
            <PlaylistingModal
              closeModalOutput={() => {
                setOpen(false);
                close && close();
              }}
            />
          </SessionProvider>
        </>
      </Modal>
      {!hideButton && (
        <Button
          className={`m0 btn-white w100p`}
          onClick={() => {
            setOpen(true);
          }}
        >
          <Icon>add</Icon>
          <span className="pl8">{t('PLAYLISTING.NEW-PLAYLIST-PITCH')}</span>
        </Button>
      )}
    </>
  );
};

export default PlaylistingFlowButton;
