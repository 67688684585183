import { useCallback, useEffect, useState } from 'react';

const useBreakpoints = (customBreakpoint?: number) => {
  const [isSmall, setIsSmall] = useState<boolean>();
  const [isMedium, setIsMedium] = useState<boolean>();
  const [isCustom, setIsCustom] = useState<boolean>();
  const handleResize = useCallback(() => {
    if (!window.visualViewport) return;
    window.visualViewport.width <= 900 ? setIsMedium(true) : setIsMedium(false);
    window.visualViewport.width <= 780 ? setIsSmall(true) : setIsSmall(false);
    if (customBreakpoint) {
      window.visualViewport.width <= customBreakpoint ? setIsCustom(true) : setIsCustom(false);
    }
  }, [customBreakpoint]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return {
    breakpointHit: isMedium,
    breakpointHitSmall: isSmall,
    breakpointHitCustom: isCustom,
  };
};

export default useBreakpoints;
