import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Autocomplete, Button, Icon, Modal, TextField } from '@mui/material';

import ArtistAPI from '@/network/ArtistAPI';
import { handleApiError } from '@/utility/api';

import Loading from '../Loading';

const AddGenreModal = ({
  title,
  isOpen,
  onClose,
  outputGenre,
}: {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  outputGenre: (genre: string) => void;
}) => {
  const { t } = useTranslation();

  const [selectedGenre, setSelectedGenre] = useState<string>('');
  const [genreOptions, setGenreOptions] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const searchGenres = useCallback(async (search: string) => {
    setIsLoading(true);
    try {
      const response = await ArtistAPI.searchGenre({ searchValue: search });
      setGenreOptions(response.data.genres);
    } catch (error: unknown) {
      handleApiError({ error });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (searchValue.length) {
      searchGenres(searchValue);
    }
  }, [searchGenres, searchValue]);

  const handleSaveGenre = useCallback(() => {
    if (selectedGenre === '') {
      setSelectedGenre('');
      onClose();
      return;
    }
    outputGenre(selectedGenre);
    setSelectedGenre('');
    onClose();
  }, [outputGenre, selectedGenre, onClose]);

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <>
          <div className="to-do-modal">
            <div className="d-flex jc-space-between mb16">
              <div>
                <h5>{t(title)}</h5>
              </div>
              <div>
                <Button className="icon-btn m0 mt-10 mr-10" onClick={onClose}>
                  <Icon>close</Icon>
                </Button>
              </div>
            </div>
            <div className="pos-rel">
              <Autocomplete
                options={genreOptions}
                noOptionsText={<p className="text-white">No options available</p>}
                getOptionLabel={(option) => option}
                value={selectedGenre}
                loading={isLoading}
                loadingText={<Loading size="small" />}
                isOptionEqualToValue={() => true}
                onChange={(_, value) => {
                  if (!value) return;
                  setSelectedGenre(value);
                }}
                inputValue={searchValue}
                onInputChange={(_, value) => setSearchValue(value)}
                renderOption={(props, option) => (
                  <li {...props} key={option} style={{ backgroundColor: '#131313' }}>
                    <div className="d-flex">
                      <div>
                        <p>{option}</p>
                      </div>
                    </div>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('PROFILE.SEARCH-FOR-GENRES')}
                    sx={{
                      '& .MuiInputLabel-outlined': {
                        paddingLeft: '20px',
                        borderColor: 'white',
                      },
                      '& .MuiInputLabel-shrink': {
                        marginLeft: '20px',
                        paddingLeft: '10px',
                        paddingRight: 0,
                        borderColor: 'white',
                      },
                      '& .MuiAutocomplete-listbox': {
                        maxHeight: 200,
                        overflow: 'auto',
                      },
                    }}
                    InputLabelProps={params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: <Icon className="text-faded">search</Icon>,
                    }}
                  />
                )}
              />
            </div>
            <Button className="btn-white w100p ml0 mt20" onClick={handleSaveGenre}>
              {t('COMMON.ADD-GENRE')}
            </Button>
          </div>
        </>
      </Modal>
    </>
  );
};

export default AddGenreModal;
