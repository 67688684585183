import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Modal } from '@mui/material';

import { FanHubWidget } from '@/components/widgets/FanHubWidget';
import { MetaAdsWidget } from '@/components/widgets/MetaAdsWidget';
import { MusicInsightsWidget } from '@/components/widgets/MusicInsights';
import { PartnerPerkWidget } from '@/components/widgets/PartnerPerkWidget';
import { PlaylistPitchesWidget } from '@/components/widgets/PlaylistPitchesWidget';
import { SocialInsightsWidget } from '@/components/widgets/SocialInsightsWidget';
import { TikTokAdsWidget } from '@/components/widgets/TikTokAdsWidget';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import useAllWidgets from '@/hooks/widgets/useAllWidgets';
import useWidgets from '@/hooks/widgets/useWidgets';
import { WidgetType } from '@/models/Widgets';

import { AIGeneratorWidget } from '../../widgets/AIGeneratorWidget';

const AllWidgetsModal = ({
  isOpen,
  onClose,
  updateWidgets,
}: {
  isOpen: boolean;
  onClose: () => void;
  updateWidgets: (widgets: WidgetType[]) => void;
}) => {
  const { t } = useTranslation();
  const { allWidgets } = useAllWidgets();
  const { breakpointHitCustom } = useBreakpoints(560);
  const { widgets } = useWidgets();

  const [selectedWidgets, setSelectedWidgets] = useState<WidgetType[]>([]);

  const handleSaveWidgets = useCallback(() => {
    updateWidgets(selectedWidgets);
  }, [selectedWidgets, updateWidgets]);

  useEffect(() => {
    setSelectedWidgets(widgets?.widgets?.map((widget) => widget.type) || []);
  }, [widgets]);

  const toggleWidget = (widget: WidgetType) => {
    if (selectedWidgets.includes(widget)) {
      setSelectedWidgets(selectedWidgets.filter((selectedWidget) => selectedWidget !== widget));
    } else {
      setSelectedWidgets([...selectedWidgets, widget]);
    }
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <>
          <div className="to-do-modal widget-modal">
            <div className="mb16 pos-rel">
              <div>
                <h5>{t('WIDGETS.ADD-A-WIDGET')}</h5>
                <p className="text-faded mt4">{t('WIDGETS.ADD-A-WIDGET-DESCRIPTION')}</p>
              </div>
              <div className="pos-abs r0 t0">
                <Button className="icon-btn m0 mt-10 mr-10" onClick={onClose}>
                  <Icon>close</Icon>
                </Button>
              </div>
            </div>
            <div className="d-flex flex-wrap gap10 max-h600 overflow-scroll widget-container">
              {allWidgets?.map((widget, index) => (
                <div
                  key={index}
                  style={{ '--animation-number': index } as CSSProperties}
                  className={`widget-card cursor-pointer pos-rel all-widget-modal-widgets flex-grow ${
                    selectedWidgets?.map((userWidget) => userWidget).includes(widget)
                      ? 'to-do-card-border'
                      : 'to-do-card-pre-border'
                  } ${breakpointHitCustom ? 'max-w500 min-w160' : 'max-w260 min-w194'}`}
                  onClick={() => {
                    toggleWidget(widget);
                  }}
                >
                  <div className="pos-abs t10 r10 z-1000">
                    {selectedWidgets?.map((userWidget) => userWidget).includes(widget) ? (
                      <div>
                        <Icon className="text-blue">check_circle</Icon>
                      </div>
                    ) : (
                      <div>
                        <Icon className="material-symbols-outlined text-faded">circle</Icon>
                      </div>
                    )}
                  </div>
                  <div className="disable">
                    {widget === 'playlist-pitches' && <PlaylistPitchesWidget />}
                    {widget === 'partner-perks' && <PartnerPerkWidget />}
                    {widget === 'meta-ads' && <MetaAdsWidget />}
                    {widget === 'tiktok-ads' && <TikTokAdsWidget />}
                    {widget === 'social-insights' && <SocialInsightsWidget />}
                    {widget === 'music-insights' && <MusicInsightsWidget />}
                    {widget === 'fan-hub' && <FanHubWidget />}
                    {widget === 'marketing-assistant' && <AIGeneratorWidget />}
                  </div>
                </div>
              ))}
            </div>
            <div className="pos-abs b0 l0 w100p">
              <Button className="btn-black-2 w100p ml0 mt20 mb0" onClick={handleSaveWidgets}>
                {t('COMMON.DONE')}
              </Button>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};

export default AllWidgetsModal;
