import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Loading = ({
  size,
  noText,
  isGeneratorLoading,
}: {
  size?: string;
  noText?: boolean;
  isGeneratorLoading?: boolean;
}) => {
  const { t } = useTranslation();
  const [loadingMessage, setLoadingMessage] = useState<string>(isGeneratorLoading ? 'LOADING-MESSAGES.GENERATING' : '');

  const startInterval = useCallback(() => {
    setTimeout(() => {
      if (isGeneratorLoading) {
        setLoadingMessage('LOADING-MESSAGES.GENERATING-1');
      } else {
        setLoadingMessage('LOADING-MESSAGES.LOADING-MESSAGE-1');
      }
    }, 5000);
    setTimeout(() => {
      if (isGeneratorLoading) {
        setLoadingMessage('LOADING-MESSAGES.GENERATING-2');
      } else {
        setLoadingMessage('LOADING-MESSAGES.LOADING-MESSAGE-2');
      }
    }, 15000);
    setTimeout(() => {
      if (isGeneratorLoading) {
        setLoadingMessage('LOADING-MESSAGES.GENERATING-3');
      } else {
        setLoadingMessage('LOADING-MESSAGES.LOADING-MESSAGE-3');
      }
    }, 25000);
  }, [isGeneratorLoading]);

  useEffect(() => {
    if (noText) {
      return;
    }
    startInterval();
  }, [noText, startInterval]);

  if (size === 'extra-small') {
    return (
      <div className="pos-rel text-center">
        <div className="loading-container loading-extra-small" data-testid="loading-component-small">
          <div className="circle circle-extra-small circle-1"></div>
          <div className="circle circle-extra-small circle-2"></div>
        </div>
        <p className="small">{t(loadingMessage)}</p>
      </div>
    );
  }

  if (size === 'small') {
    return (
      <div className="pos-rel text-center">
        <div className="loading-container loading-small" data-testid="loading-component-small">
          <div className="circle circle-small circle-1"></div>
          <div className="circle circle-small circle-2"></div>
        </div>
        <p className="small">{t(loadingMessage)}</p>
      </div>
    );
  }
  return (
    <div className="pos-rel text-center">
      <div className="loading-container" data-testid="loading-component">
        <div className="circle circle-1"></div>
        <div className="circle circle-2"></div>
      </div>
      <p className="small">{t(loadingMessage)}</p>
    </div>
  );
};

export default Loading;
