import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, Modal } from '@mui/material';

const AddCollaboratorModal = ({
  isOpen,
  onClose,
  output,
}: {
  isOpen: boolean;
  onClose: () => void;
  output: (link: string) => void;
}) => {
  const { t } = useTranslation();
  const [collaborator, setCollaborator] = useState<string>('');

  const sendCollaborator = () => {
    if (collaborator === '') {
      setCollaborator('');
      onClose();
      return;
    }
    output(collaborator);
    setCollaborator('');
    onClose();
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <div className="to-do-modal">
          <div className="d-flex jc-space-between mb16">
            <div>
              <h5>{'Add a collaborator'}</h5>
              <p className="text-faded small mt8">{'Add any people that you worked with on this track'}</p>
            </div>
            <div>
              <Button className="icon-btn m0 mt-10 mr-10" onClick={onClose}>
                <Icon>close</Icon>
              </Button>
            </div>
          </div>
          <div className="pos-rel">
            <input
              placeholder={'Add a collaborator'}
              value={collaborator}
              onChange={(event) => setCollaborator(event.target.value)}
            />
          </div>
          <Button className="btn-white w100p ml0 mt20" onClick={sendCollaborator}>
            {t('COMMON.ADD-COLLABORATOR')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AddCollaboratorModal;
