import React, { ReactNode } from 'react';

import { motion } from 'framer-motion';
export const MotionWrapper = ({
  children,
  key,
  className,
  isNext,
  ...props
}: {
  children: ReactNode;
  key: string | number;
  className?: string;
  isNext?: boolean;
}) => {
  return (
    <motion.div
      key={key}
      className={`${className ?? ''} min-h100vh-padding w100p`}
      {...props}
      initial={{ opacity: 0, x: isNext ? '100%' : '-100%' }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: isNext ? '-100%' : '100%' }}
      transition={{ type: 'spring', duration: 0.4, bounce: 0.2 }}
    >
      {children}
    </motion.div>
  );
};
