import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Modal } from '@mui/material';

import { PromoteFlowQueryParams } from '@/models/Enums';

import AIGeneratorModal from '../../promote-modals/AIGeneratorModal';

export const ArtistBioFlow = () => {
  const [flowOpen, setFlowOpen] = useState<boolean>(false);
  const [type, setType] = useState<string>('artist-bio');
  const [params, setSearchParams] = useSearchParams();

  const closeFlow = () => {
    params.delete(PromoteFlowQueryParams.ARTIST_BIO);
    setSearchParams(params);
    setFlowOpen(false);
  };

  useEffect(() => {
    if (params.get(PromoteFlowQueryParams.ARTIST_BIO) === 'true') {
      setType('artist-bio');
      setFlowOpen(true);
    } else if (params.get(PromoteFlowQueryParams.PRESS_RELEASE) === 'true') {
      setType('press-release');
      setFlowOpen(true);
    } else if (params.get(PromoteFlowQueryParams.BASIC_INFO) === 'true') {
      setType('basic-info');
      setFlowOpen(true);
    } else {
      setFlowOpen(false);
    }
  }, [params]);

  return (
    <>
      <Modal
        open={flowOpen}
        onClose={() => {
          closeFlow();
        }}
      >
        <>
          <AIGeneratorModal
            type={type}
            closeModalOutput={() => {
              closeFlow();
            }}
          />
        </>
      </Modal>
    </>
  );
};
