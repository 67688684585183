import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Icon } from '@mui/material';
import { gsap } from 'gsap';

import { navItems } from '@/constants/NavModel';
import useSubscription from '@/hooks/account/useSubscription';
import useIntercomContext from '@/hooks/context/useIntercomContext';
import { useWalkthrough } from '@/hooks/context/useWalkthrough';
import { VIEW_STATE } from '@/models/Enums';
import { useLocalStorage } from '@/services/LocalStorage';

import Logout from '../auth/Logout';
import ButtonComponent from '../microcomponents/Button';
import CustomIcons from '../microcomponents/CustomIcons';
import { BetaTag } from '../statuses/BetaTag';

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [params, setSearchParams] = useSearchParams();
  const { viewState, setViewState } = useWalkthrough();
  const [hasWalkthrough, setHasWalkthrough] = useState<boolean>(false);
  const { isSubscribed } = useSubscription();
  const { getItem, setItem, removeItem } = useLocalStorage();
  const itemsRef = React.useRef<(SVGSVGElement | null)[]>([]);

  const [minimize, setMinimize] = useState<boolean>(false);
  const [breakPointHit, setBreakPointHit] = useState<boolean>(false);
  const [showIntercom, setShowIntercom] = useState<boolean>(false);

  const { isOpen, dispatchActivateIntercom, dispatchDeactivateIntercom, dispatchOpenIntercom } = useIntercomContext();

  const handleIntercom = () => {
    setShowIntercom(true);
    dispatchActivateIntercom('navbar');
    dispatchOpenIntercom();
  };

  useEffect(() => {
    if (
      location.pathname.includes('home') ||
      (location.pathname.includes('release-cycles') && params.get('view') === 'day')
    ) {
      setHasWalkthrough(true);
    } else {
      setHasWalkthrough(false);
    }
  }, [location, params, setSearchParams]);

  const toggleNav = () => {
    const rootElement = document.documentElement;
    const widthValue = minimize ? '--sidebar-width-default' : '--sidebar-width-minimized';

    rootElement.style.setProperty('--sidebar-width', `var(${widthValue})`);

    if (minimize) {
      removeItem('nav-bar-minimize');
    } else {
      setItem('nav-bar-minimize', 'true');
    }
    setMinimize(!minimize);
  };

  useEffect(() => {
    if (!isOpen) {
      setShowIntercom(false);
    }
  }, [dispatchOpenIntercom, isOpen, location.pathname, showIntercom]);

  useEffect(() => {
    if (!showIntercom) {
      dispatchDeactivateIntercom('navbar');
    }
  }, [dispatchDeactivateIntercom, showIntercom]);

  useEffect(() => {
    return () => {
      dispatchDeactivateIntercom('navbar');
    };
  }, [dispatchDeactivateIntercom]);

  const handleResize = () => {
    window.visualViewport && window.visualViewport.width <= 900 ? setBreakPointHit(true) : setBreakPointHit(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (getItem('nav-bar-minimize')) {
      const rootElement = document.documentElement;
      const widthValue = '--sidebar-width-minimized';

      rootElement.style.setProperty('--sidebar-width', `var(${widthValue})`);

      setMinimize(true);
    } else {
      setMinimize(false);
    }
  }, [getItem, minimize]);

  useEffect(() => {
    gsap.fromTo(
      itemsRef.current,
      { opacity: 0, scale: 0.5, y: 10 },
      { opacity: 1, scale: 1, y: 0, duration: 0.2, stagger: 0.07, ease: 'power3.out' }
    );
  }, []);

  const excludedPaths = [
    '/login',
    '/payment-confirmation/meta',
    '/onboarding',
    '/email-verification',
    '/payment-confirmation/tiktok',
    '/payment-confirmation/playlist',
    '/payment-confirmation/subscription',
    '/release-complete',
  ];

  if (excludedPaths.includes(location.pathname)) return null;

  return (
    <>
      {!breakPointHit && (
        <div
          id="nav-container"
          className={minimize ? 'nav-container minimized card-inner' : 'nav-container card-inner'}
        >
          {!minimize && (
            <div data-testid="minimize-button-left" className="minimize-arrow" onClick={toggleNav}>
              <Icon>chevron_left</Icon>
            </div>
          )}
          {minimize && (
            <div data-testid="minimize-button-right" className="minimize-arrow" onClick={toggleNav}>
              <Icon>chevron_right</Icon>
            </div>
          )}
          <div onClick={() => navigate('/')}>
            <img
              className="cursor-pointer logo-img"
              data-testid="main-logo"
              src={minimize ? '/images/logos/unhurd-logo.png' : '/images/logos/full-text-logo.svg'}
              alt="unhurd-logo"
            />
          </div>
          <nav className="nav-sidebar">
            {navItems.map((item, index) => (
              <div
                key={item.title}
                className="min-h48"
                id={`nav-bar-item-${item.name}`}
                data-testid={`nav-bar-item-${item.name}`}
                ref={(el) => (itemsRef.current[index] = el as SVGSVGElement | null)}
              >
                <NavLink
                  className={`${minimize ? 'nav-link-item minimized' : 'nav-link-item'} ${item.name}`}
                  data-testid={`nav-item-${item.name}`}
                  to={item.navLink}
                  aria-label={item.name}
                >
                  <CustomIcons className="nav-link-icon material-symbols-outlined" name={item.icon} />
                  <span className={minimize ? 'nav-link-text minimize' : 'nav-link-text mt2'}>{t(item.title)}</span>
                  {item.isBeta && !minimize && <BetaTag isGreen className="mt4 ml-auto" />}
                  {item.isPro && !minimize && !isSubscribed && (
                    <CustomIcons className="pt2 ml-auto mr4 mt4 text-faded" name="crown" />
                  )}
                </NavLink>
              </div>
            ))}
            <div
              className={`mt-auto pl8 pr8 pb8 ${hasWalkthrough ? '' : 'hidden'}`}
              ref={(el) => (itemsRef.current[navItems.length + 1] = el as SVGSVGElement | null)}
            >
              <div
                className={`walkthrough-resume-button-border w100p ${viewState && viewState === VIEW_STATE.SKIPPED ? '' : ''} ${hasWalkthrough ? '' : 'hidden'}`}
              >
                <div className={`walkthrough-resume-button pt8 pb8 ${minimize ? 'jc-center pt8 pb4' : 'pl4'}`}>
                  <ButtonComponent
                    isCustom
                    className={`w100p text-faded ${minimize ? 'jc-center' : ''}`}
                    onClick={() => setViewState(VIEW_STATE.UNSEEN)}
                  >
                    <CustomIcons className="logout-icon text-faded walkthrough-icon nav-link-icon" name="walkthrough" />
                    {!minimize && (
                      <p
                        className={`nav-footer-text ${minimize ? 'minimize' : ''} logout-text p4 pl12 fs-custom text-faded`}
                        style={{ '--customFontSize': '14px' } as CSSProperties}
                      >
                        {t('WALKTHROUGH.WALKTHROUGH')}
                      </p>
                    )}
                  </ButtonComponent>
                </div>
              </div>
            </div>
            <div
              key={'support'}
              className={`pt8 pb8 w100p ${minimize ? 'minimized jc-center' : 'pl8 pr4'}`}
              id={`nav-bar-item-support`}
              data-testid={`nav-bar-item-support`}
              ref={(el) => (itemsRef.current[navItems.length + 2] = el as SVGSVGElement | null)}
            >
              <ButtonComponent
                isCustom
                className={`w100p  ${showIntercom ? '' : 'text-faded'} nav-footer-item ${minimize ? 'minimized jc-center' : 'pl8'}`}
                onClick={handleIntercom}
              >
                <Icon className={`nav-link-icon ${showIntercom ? 'text-white' : 'text-faded'} `}>help_outline</Icon>
                {!minimize && (
                  <p
                    className={`nav-footer-text ${minimize ? 'minimize' : ''} logout-text p4 pl12 fs-custom ${showIntercom ? '' : 'text-faded'}`}
                    style={{ '--customFontSize': '14px' } as CSSProperties}
                  >
                    {t('SETTINGS.SUPPORT')}
                  </p>
                )}
              </ButtonComponent>
            </div>
            <div
              key={'settings'}
              className={`w100p ${minimize ? 'minimized jc-center' : 'pl8'}`}
              id={`nav-bar-item-settings`}
              data-testid={`nav-bar-item-settings`}
              ref={(el) => (itemsRef.current[navItems.length + 3] = el as SVGSVGElement | null)}
            >
              <NavLink
                className={`nav-link-item w100p ${minimize ? 'minimized pr8 m0' : 'm0'}  `}
                data-testid={`nav-item-settings`}
                to={'/settings'}
                aria-label={'settings'}
              >
                <CustomIcons className="nav-link-icon material-symbols-outlined mr2" name={'settings'} />
                <span
                  className={`${minimize ? 'minimize' : ''} nav-footer-text pl12 p4 pt6 fs-custom`}
                  style={{ '--customFontSize': '14px' } as CSSProperties}
                >
                  {t('NAVIGATION.SETTINGS')}
                </span>
              </NavLink>
            </div>
            <div className="nav-footer-divider pl8 pr8" />
            <div
              className="pl10 pr10 pb10"
              ref={(el) => (itemsRef.current[navItems.length + 4] = el as SVGSVGElement | null)}
            >
              <Logout minimize={minimize} />
            </div>
            {/* <div className={`nav-footer ${minimize ? ' minimize' : ''}`}></div> */}
          </nav>
        </div>
      )}
    </>
  );
};

export default NavBar;
