import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO } from 'date-fns';
import en from 'date-fns/locale/en-GB';

import { CareerHistoryObject } from '@/models/MarketingAssistant';

const AddCareerEventModal = ({
  open,
  output,
}: {
  open: boolean;
  output: (value: CareerHistoryObject | boolean) => void;
}) => {
  const { t } = useTranslation();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const initialEventState: CareerHistoryObject = useMemo(() => {
    return {
      date: new Date().toISOString(),
      description: '',
    };
  }, []);
  const [event, setEvent] = useState<CareerHistoryObject>(initialEventState);

  const formatEvent = (event: CareerHistoryObject) => {
    return {
      date: format(new Date(event.date), 'yyyy-MM-dd'),
      description: event.description,
    };
  };

  useEffect(() => {
    setDialogOpen(open);
    return () => {
      setEvent(initialEventState);
    };
  }, [initialEventState, open]);

  return (
    <>
      <Dialog
        className="p10"
        open={dialogOpen}
        onClose={(_, reason) => {
          if (reason === 'backdropClick') {
            output(false);
            setDialogOpen(false);
          }
          setDialogOpen(false);
        }}
      >
        <DialogTitle className="p0 d-flex jc-space-between">
          {t('Add an event')}
          <div>
            <Button
              className="icon-btn m0"
              onClick={() => {
                output(false);
              }}
            >
              <Icon>close</Icon>
            </Button>
          </div>
        </DialogTitle>
        <DialogContent className="p0 mt16">
          <p className="mb8">Date</p>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={en}>
            <DesktopDatePicker
              format="dd-MM-yyyy"
              value={parseISO(new Date(event.date as string).toISOString())}
              onChange={(value) => {
                setEvent({ ...event, date: value ? value.toISOString() : '' });
              }}
            />
          </LocalizationProvider>

          <p className="mt16">Event</p>
          <div className="mt8">
            <input
              placeholder={t('What happened?')}
              maxLength={50}
              value={event.description ?? ''}
              onChange={(e) => setEvent({ ...event, description: e.target.value })}
            />
          </div>
        </DialogContent>
        <DialogActions className="p0 mt32">
          <Button
            className="w100p m0 btn-white"
            disabled={!event.description}
            onClick={() => {
              if (!event.description) {
                return;
              }
              output(formatEvent(event));
              setDialogOpen(false);
            }}
          >
            {t('Add event')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddCareerEventModal;
