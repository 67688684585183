import React from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';

import { RegEx } from '@/constants/RegEx';
import { UserSocialsModel } from '@/models/MarketingAssistant';

export const SocialMediaInputs = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <div className="d-flex gap10 w100p mt10">
        <img src="/images/logos/instagram-logo.svg" alt="instagram" />
        <input
          className={(errors?.socials as unknown as UserSocialsModel)?.instagram ? 'error-outline' : ''}
          {...register('socials.instagram', {
            pattern: {
              value: RegEx.socialPlatforms?.instagram,
              message:
                'Invalid Instagram username format. Must start with @ and contain only letters, numbers, and underscores',
            },
            validate: (value) => {
              return (
                value === '' ||
                RegEx.socialPlatforms.instagram.test(value) ||
                'Invalid Instagram username format. Must start with @ and contain only letters, numbers, and underscores'
              );
            },
          })}
          placeholder="Your Instagram @"
        />
      </div>
      {(errors?.socials as unknown as UserSocialsModel)?.instagram && (
        <p className="text-error small pt0 pl40 text-left">
          {String((errors?.socials as FieldErrors<UserSocialsModel>)?.instagram?.message ?? '')}
        </p>
      )}
      <div className="d-flex gap10 w100p mt10">
        <img src="/images/logos/tiktok-logo.svg" alt="tiktok" />
        <input
          className={(errors?.socials as unknown as UserSocialsModel)?.tikTok ? 'error-outline' : ''}
          {...register('socials.tikTok', {
            pattern: {
              value: RegEx.socialPlatforms?.tiktok,
              message: 'Invalid TikTok username format. Must start with @ and and be 2-24 characters long',
            },
            validate: (value) => {
              return (
                value === '' ||
                RegEx.socialPlatforms.tiktok.test(value) ||
                'Invalid TikTok username format. Must start with @ and and be 2-24 characters long'
              );
            },
          })}
          placeholder="Your TikTok @"
        />
      </div>
      {(errors?.socials as unknown as UserSocialsModel)?.tikTok && (
        <p className="text-error small pt0 pl40 text-left">
          {String((errors?.socials as FieldErrors<UserSocialsModel>)?.tikTok?.message ?? '')}
        </p>
      )}
      <div className="d-flex gap10 w100p mt10">
        <img src="/images/logos/facebook-logo.svg" alt="facebook" />
        <input
          className={(errors?.socials as unknown as UserSocialsModel)?.facebook ? 'error-outline' : ''}
          {...register('socials.facebook', {
            pattern: {
              value: RegEx.urlRegEx,
              message: 'Invalid Facebook link. Must be a valid URL',
            },
            validate: (value) => {
              return value === '' || RegEx.urlRegEx.test(value) || 'Invalid Facebook link. Must be a valid URL';
            },
          })}
          placeholder="Paste your Facebook page link here"
        />
      </div>
      {(errors?.socials as unknown as UserSocialsModel)?.facebook && (
        <p className="text-error small pt0 pl40 text-left">
          {String((errors?.socials as FieldErrors<UserSocialsModel>)?.facebook?.message ?? '')}
        </p>
      )}
      <div className="d-flex gap10 w100p mt10">
        <img src="/images/logos/youtube-logo.svg" alt="youtube" />
        <input
          className={(errors?.socials as unknown as UserSocialsModel)?.youTube ? 'error-outline' : ''}
          {...register('socials.youTube', {
            pattern: {
              value: RegEx.urlRegEx,
              message: 'Invalid YouTube link. Must be a valid URL',
            },
            validate: (value) => {
              return value === '' || RegEx.urlRegEx.test(value) || 'Invalid YouTube link. Must be a valid URL';
            },
          })}
          placeholder="Paste your YouTube channel link here"
        />
      </div>
      {(errors?.socials as unknown as UserSocialsModel)?.youTube && (
        <p className="text-error small pt0 pl40 text-left">
          {String((errors?.socials as FieldErrors<UserSocialsModel>)?.youTube?.message ?? '')}
        </p>
      )}
      <div className="d-flex gap10 w100p mt10">
        <img src="/images/logos/twitter-logo.svg" alt="twitter" />
        <input
          className={(errors?.socials as unknown as UserSocialsModel)?.twitter ? 'error-outline' : ''}
          {...register('socials.twitter', {
            pattern: {
              value: RegEx.socialPlatforms?.twitter,
              message: 'Invalid X username format. Must start with @ and be 4-15 characters long',
            },
            validate: (value) => {
              return (
                value === '' ||
                RegEx.socialPlatforms.twitter.test(value) ||
                'Invalid X username format. Must start with @ and be 4-15 characters long'
              );
            },
          })}
          placeholder="Your X @"
        />
      </div>
      {(errors?.socials as unknown as UserSocialsModel)?.twitter && (
        <p className="text-error small pt0 pl40 text-left">
          {String((errors?.socials as FieldErrors<UserSocialsModel>)?.twitter?.message ?? '')}
        </p>
      )}
      <div className="d-flex gap10 w100p mt10">
        <img src="/images/logos/snapchat-logo-white.svg" alt="snapchat" />
        <input
          className={(errors?.socials as unknown as UserSocialsModel)?.snapChat ? 'error-outline' : ''}
          {...register('socials.snapChat', {
            pattern: {
              value: RegEx.socialPlatforms?.snapchat,
              message: 'Invalid Snapchat username format. Must start with a letter and be 3-15 characters long',
            },
            validate: (value) => {
              return (
                value === '' ||
                RegEx.socialPlatforms.snapchat.test(value) ||
                'Invalid SnapChat username format. Must start with a letter and be 3-15 characters long'
              );
            },
          })}
          placeholder="Your Snapchat username"
        />
      </div>
      {(errors?.socials as unknown as UserSocialsModel)?.snapChat && (
        <p className="text-error small pt0 pl40 text-left">
          {String((errors?.socials as FieldErrors<UserSocialsModel>)?.snapChat?.message ?? '')}
        </p>
      )}
      <div className="d-flex gap10 w100p mt10">
        <img src="/images/logos/discord-logo-white.svg" alt="discord" />

        <input
          className={(errors?.socials as unknown as UserSocialsModel)?.discord ? 'error-outline' : ''}
          {...register('socials.discord', {
            pattern: {
              value: RegEx.socialPlatforms?.discord,
              message: 'Invalid Discord username format. Must be 2-32 characters long',
            },
            validate: (value) => {
              return (
                value === '' ||
                RegEx.socialPlatforms.discord.test(value) ||
                'Invalid Discord username format. Must be 2-32 characters long'
              );
            },
          })}
          placeholder="Your Discord username"
        />
      </div>
      {(errors?.socials as unknown as UserSocialsModel)?.discord && (
        <p className="text-error small pt0 pl40 text-left">
          {String((errors?.socials as FieldErrors<UserSocialsModel>)?.discord?.message ?? '')}
        </p>
      )}
    </>
  );
};
